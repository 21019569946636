import React, { Fragment } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import styles from "../Layout/utils/layout.module.scss"

import SEO from "./SEO"
import Navbar from "./Navbar"
import Notification from "./Notification"
import HelpCenterBanner from "../StaticPages/HelpCenter/HelpCenterBanner"
import Footer from "./Footer"
import Container from "layout/Container"

/**
 * @param {string} seoTitle string
 * @param {string} title string
 * @param {string} subtitle string
 * @param {array} path array
 * @param {object} display settings to display footer/help banner
 * @param {} children
 */

const MobileLayout = ({
  children,
  title,
  subtitle,
  seoTitle,
  display = { footer: true, helpCenterBanner: true },
  path,
}) => {
  let pathArray = path ? path.split("/") : []

  return (
    <Fragment>
      <SEO title={seoTitle} />
      <Navbar path={pathArray} />
      <Notification />
      {title && (
        <h4
          className={classNames(
            "has-text-left has-text-primary is-size-4 mb-0 mx-2-mobile pb-1"
          )}
        >
          {title}
          <p
            className={classNames(
              "subtitle has-text-grey-dark has-text-left is-size-6 pb-1"
            )}
          >
            {subtitle}
          </p>
        </h4>
      )}
      <main className="mb-3 mx-1">{children}</main>
      {display.helpCenterBanner && <HelpCenterBanner />}
      {display.footer && <Footer />}
    </Fragment>
  )
}

MobileLayout.propTypes = {
  children: PropTypes.node.isRequired,
  seoTitle: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  path: PropTypes.array,
}

export default MobileLayout
