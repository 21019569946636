import React from "react"
import classNames from "classnames"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSearch, faCircleNotch } from "@fortawesome/free-solid-svg-icons"

import styles from "../utils/search.module.scss"

const MobileSearchInput = ({
  children,
  isLoading,
  searchDispatch,
  searchQuery,
}) => {
  const handleClear = () => {
    searchDispatch({
      type: "SET_SEARCH_QUERY",
      payload: "",
    })
    searchDispatch({
      type: "SET_SEARCH_RESULTS",
      payload: [],
    })
  }

  return (
    <section
      className={classNames(
        "is-justify-content-space-between",
        styles["mobile_search"]
      )}
    >
      <div
        className={classNames("is-flex", styles["mobile_search__container"])}
      >
        <button
          className={classNames(
            "p-1-mobile is-medium",
            styles["mobile_search__button"]
          )}
        >
          <FontAwesomeIcon
            icon={isLoading ? faCircleNotch : faSearch}
            spin={isLoading}
            className={classNames("icon", styles["icon"])}
          />
        </button>
        {children}
        {!!searchQuery ? (
          <button
            className={classNames(" delete", styles["mobile_search__cancel"])}
            onClick={handleClear}
          />
        ) : null}
      </div>
      <div></div>
    </section>
  )
}

export default MobileSearchInput
