import React from "react"
import classNames from "classnames"
import styles from "./utils/elements.module.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const FloatingActionButton = ({ link, children, icon, handleOnClick, showBadge }) => {
  return (
    <a
      // href={link || ""}
      target="_blank"
      className={classNames(styles["floatingButton"])}
      onClick={handleOnClick}
    >
      <FontAwesomeIcon
        icon={icon}
        className={classNames(styles["floatingButton__icon"])}
      />
      {showBadge && (
        <span
        className={classNames(styles["floatingButton__badge"])}
        ></span>
      )}
    </a>
  )
}

export default FloatingActionButton
